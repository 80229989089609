import React, { useEffect, useState } from "react";
import accountSelectIcon from "../../assets/img/icons/accountSelectIcon.svg";
import Nube from "../../assets/img/icons/nube.svg";
import Arrow from "../../assets/img/icons/Arrow-gray.svg";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { selectAccount, saveAccount } from "../../actions/loginActions";
import myStyles from "./AccountSelect.module.css";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { loader, message, swalConfirm } from "../../helpers/helpers";
import { LOGOUT_SUCCESS, USER_LOGIN_SUCCESS } from './../../actions/actionTypes';

function AccountSelectRender(props) {
  const history = useHistory();
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(0); 
  const [rolArtControl, setrolArtControl] = useState(null);
  const [show, setShow] = useState(false);

  const onRedirectLogin = () => {
    dispatch({
      type: LOGOUT_SUCCESS
    });

    history.push("/login");
  };

  useEffect(() => {
    const userData = counter?.loginReducer?.user_data;

    if (userData && userData.id) {
      dispatch(selectAccount({ userid: userData.id, idAccount: userData.eaccount, idProfile: userData.profile }, onRedirectLogin));
    }
  }, [trigger]);

  const handleClick = () => {
    if (!!props.afterClick) {
      props.afterClick();
      history.push("/nomina/inicio");
    }
  };

  const renderRol = (account) => {
    if (Array.isArray(account.profile)) {
      return account?.profile.map((rol) => {
        return (
          <div
            key={rol.id}
            className={`${myStyles.flex2} ${myStyles.containerChildren}`}
            onClick={() => {
              dispatch(
                saveAccount(
                  {
                    ...account,
                    profile: rol,
                  },
                  handleClick
                ));
            }}
          >
            <span>{rol?.nombre?.toUpperCase()}</span>
          </div>
        );
      });
    } else {
      return <div
        key={account.profile.id}
        className={`${myStyles.flex2} ${myStyles.containerChildren}`}
        onClick={() => {
          dispatch(
            saveAccount({ ...account }, handleClick)
          );
        }}
      >
        <span>{account.profile.nombre}</span>
      </div>;
    }
  };

  const changeInfoAccountInPayroll = (id) => {
    if (!!counter?.loginReducer?.user_data?.payrollInfo) {
      let tempObj = counter?.loginReducer?.user_data;
      if (Array.isArray(counter?.loginReducer?.user_data?.payrollInfo)) {
        tempObj.accountsRelated = counter?.loginReducer?.user_data?.payrollInfo;
        let payrollInfo = counter?.loginReducer?.user_data?.payrollInfo.find((e) => e.entity_account === id) ?? {};
        tempObj.payrollInfo = payrollInfo;
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: tempObj,
          Authorization: token
        });
      } else {
        let payrollInfo = counter?.loginReducer?.user_data?.accountsRelated.find((e) => e.entity_account === id) ?? {};
        tempObj.payrollInfo = payrollInfo;
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: tempObj,
          Authorization: token
        });
      }
    }
  };

  const renderAccounts = () => {
    const accounts = counter?.loginReducer?.accounts;
    const isLoading = counter?.loginReducer?.loading;

    if (isLoading) {
      return (
        <div key="loading" className={myStyles.flex1}>
          <p>Cargando cuentas...</p>
        </div>
      );
    }

    if (!accounts || accounts.length === 0) {
      return (
        <div key="no-accounts" className={myStyles.flex1}>
          <p>Este usuario no tiene cuentas accesibles</p>
        </div>
      );
    }

    return accounts.map((acc, index) => {
      const isLastAccount = index === accounts.length - 1;

      return (
        <div key={acc.id} className="row" style={{ height: "max-content" }}>
          <div
            className={`${myStyles.flex1} col-5`}
            onClick={() => {
              changeInfoAccountInPayroll(acc.id);
              setrolArtControl(index);
              setShow(true);
            }}
          >
            <p className={`my-auto ${myStyles.classTitle}`}>{acc.name}</p>
            <img alt={acc.name} src={Arrow} style={{ width: "15px" }} />
          </div>

          <div className={`col-5 ${accounts[rolArtControl || 0]?.profile?.length === 1 ? 'align-items-center d-flex' : ''}`}>
            {(index === rolArtControl && show) && (
              <div className={`col-3 ${myStyles.containerInter} my-auto mx-2 animate__animated animate__fadeIn`}>
                {renderRol(acc)}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={myStyles.accountBoxWrap}>
      {renderAccounts()}
    </div>
  );
}

AccountSelectRender.propTypes = {
  afterClick: PropTypes.func
};

export default AccountSelectRender;