import { decode } from "jsonwebtoken";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SAVE_USER_INFO, USER_LOGIN_SUCCESS } from "../actions/actionTypes";
import { getCategories, selectAccount } from "../actions/loginActions";
import { getParamsObject, hasData } from "../helpers";

// helpers
import { loader } from "../helpers/helpers";

export const ValidatePage = () => {
  const params = getParamsObject(window.location.search);
  const history = useHistory();

  const dispatch = useDispatch();

  const idModule = params.idModule;
  const myPayroll = params.myPayroll;
  const mySetting = params.mySetting;
  const nameModule = params.nameModule;
  const idUser = params.idUser;
  const idAccount = params.idAccount;
  const idProfile = params.idProfile;
  const userImage = params.userImage;
  const fullNameUser = params.fullNameUser;
  const token = params.token;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (
      hasData(idModule) &&
      hasData(myPayroll) &&
      hasData(mySetting) &&
      hasData(nameModule) &&
      hasData(idUser) &&
      hasData(idAccount) &&
      hasData(idProfile) &&
      hasData(userImage) &&
      hasData(fullNameUser) &&
      hasData(decode(token)?.user_data)
    ) {
      if (decode(token)?.user_data.payrollInfo) {
        let tempObj = decode(token)?.user_data;
        if (Array.isArray(decode(token)?.user_data?.payrollInfo)) {
          tempObj.accountsRelated = decode(token)?.user_data?.payrollInfo;
          let payrollInfo =
            decode(token)?.user_data?.payrollInfo.find(
              (e) => Number(e.entity_account) === Number(idAccount)
            ) ?? {};
          tempObj.payrollInfo = payrollInfo;
          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: tempObj,
            Authorization: token,
          });
        } else {
          let payrollInfo =
            decode(token)?.user_data?.accountsRelated.find(
              (e) => Number(e.entity_account) === Number(idAccount)
            ) ?? {};
          tempObj.payrollInfo = payrollInfo;
          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: tempObj,
            Authorization: token,
          });
        }
      }
      dispatch({
        type: SAVE_USER_INFO,
        payload: decode(token)?.user_data,
      });

      dispatch(getCategories(idProfile));

      dispatch(
        selectAccount({
          idAccount,
          userid: idUser,
          idProfile,
          myPayroll: Number(myPayroll),
          mySetting: Number(mySetting),
          redirect: true,
        })
      );
    } else {
      window.location.replace(
        `${process.env.REACT_APP_URL_PROTOCOL}${process.env.REACT_APP_URL_BASE}/categoryselect`
      );
    }
  }, [
    dispatch,
    fullNameUser,
    history,
    idAccount,
    idModule,
    idProfile,
    idUser,
    myPayroll,
    mySetting,
    nameModule,
    params,
    token,
    userImage,
  ]);

  return loader;
};
